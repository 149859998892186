import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewChecked,
  Input,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { first, takeUntil } from 'rxjs/operators';
import { Subject, forkJoin, fromEvent, lastValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { ContainerService } from 'src/app/core/container.service';
import { ErrorService } from 'src/app/core/error.service';
import { SidenavDrawerService } from 'src/app/core/sidenav-drawer.service';
import {
  ContainerAnswer,
  ContainerStationInformation,
  ConnectedStationInfo,
  MoveContainer,
  GridsterItemWidget,
  QuestionFieldType,
  WidgetType,
  CanNavigate,
  ManualTrigger,
  StationInformation,
  ActionsStatusRunner,
  StateActions,
  StationRosterMember,
  Question,
  ActionType,
  ManualTriggerStation,
} from 'src/models';
import { GridsterConfig } from 'angular-gridster2';
import { PopupService } from 'src/app/core/popup.service';
import { UserService } from 'src/app/core/user.service';
import { SubHeaderComponent } from 'src/app/shared/sub-header/sub-header.component';
import { StationService } from 'src/app/core/station.service';
import {
  DashboardWidgets,
  InputFrame,
  LibraryHelper,
  MobileBrowserChecker,
  STATES,
  TermsGeneric,
} from 'src/helpers';
import { RouterNavigationService } from 'src/app/core/router-navigation.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { StationToMoveComponent } from 'src/app/shared/station-to-move/station-to-move.component';
import { PowerService } from 'src/app/core/power.service';
import { SplitService } from 'src/app/core/split.service';
import { FlowContainerPreviousStationComponent } from 'src/app/shared/flow-container-previous-station/flow-container-previous-station.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import _ from 'lodash';
import { ActionRunnerHelper } from 'src/helpers/action-runner-helper';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingIndicatorComponent } from 'src/app/shared/loading-indicator/loading-indicator.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContainerInfoHeaderComponent } from 'src/app/shared/container-info-header/container-info-header.component';
import { DetailDrawerComponent } from 'src/app/shared/detail-drawer/detail-drawer.component';
import { InfoDrawerComponent } from 'src/app/shared/info-drawer/info-drawer.component';
import { StationInfoHeaderComponent } from 'src/app/shared/station-info-header/station-info-header.component';
import { ContainerTemplateComponent } from '../container-template/container-template.component';

/**
 * Main component for viewing a document.
 */
@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatExpansionModule,
    MatSidenavModule,
    MatTooltipModule,
    MatDialogModule,
    DetailDrawerComponent,
    InfoDrawerComponent,
    StationInfoHeaderComponent,
    LoadingIndicatorComponent,
    ContainerInfoHeaderComponent,
    ContainerTemplateComponent,
    FlowContainerPreviousStationComponent,
    StationToMoveComponent,
  ],
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent
  implements OnInit, OnDestroy, AfterViewChecked, CanNavigate
{
  /** The component for the drawer that houses comments and history. */
  @ViewChild('detailDrawer', { static: true })
  detailDrawer!: MatDrawer;

  /** The component for the subheader component. */
  @ViewChild('subHeaderComponent')
  subHeaderComponent!: SubHeaderComponent;

  /** Whether de container is displayed inside a widget or not. */
  @Input() isWidget = false;

  /** Id for station in widget. */
  @Input() stationRithmIdWidget!: string;

  /** Id for document id widget. */
  @Input() documentRithmIdWidget!: string;

  /** Observable for when the component is destroyed. */
  private destroyed$ = new Subject<void>();

  /** Container form. */
  containerForm: FormGroup<{
    /** Form control field containerTemplateForm. */
    containerTemplateForm: FormControl<string | null>;

    /** Form control field inputFrameFieldForm.  */
    inputFrameFieldForm: FormControl<string | null>;
  }>;

  /** The information about the document within a station. */
  containerInformation!: ContainerStationInformation;

  /** Different types of input frames components.*/
  frameType = WidgetType;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Document Id. */
  containerId = '';

  /** Station Id. */
  stationId = '';

  /** Get container Name from BehaviorSubject. */
  private containerName = '';

  /** The context of what is open in the drawer. */
  drawerContext = 'comments';

  /** Get flow button name. */
  flowButtonName = '';

  /** Whether the request to get the container info is currently underway. */
  containerLoading = true;

  /** Show or hidden accordion for all field. */
  accordionFieldAllExpanded = false;

  /** Expands/collapse the responsive footer. */
  footerExpanded = false;

  /** To check click SubHeader. */
  clickSubHeader = false;

  /** To check click comment. */
  clickComment = false;

  /** Whether the document allow previous button or not. */
  allowPreviousButton = false;

  /** Should flow the container. */
  shouldFlowContainer = false;

  /** Should flow the container to the previous station. */
  shouldFlowPreviousContainer = false;

  /** Whether getContainerWidgets has been requested or not yet. */
  widgetFramesLoaded = false;

  /** Document name form shown in document info. */
  documentNameForm = false;

  /** Grid initial values. */
  options: GridsterConfig = {
    gridType: 'verticalFixed',
    fixedRowHeight: 50,
    displayGrid: 'none',
    pushItems: false,
    draggable: {
      enabled: false,
      ignoreContent: false,
    },
    resizable: {
      enabled: false,
    },
    margin: 12,
    minCols: 24,
    maxCols: 24,
    maxRows: 500,
    outerMargin: true,
    outerMarginLeft: 16,
    outerMarginRight: 16,
    outerMarginTop: 16,
  };

  /** The list of stations that this container could flow to (next stations). */
  forwardStations: ConnectedStationInfo[] = [];

  /** The list of stations that this container came from. */
  previousStations: ConnectedStationInfo[] = [];

  /** The all container answers the container actually. */
  containerAnswer: ContainerAnswer[] = [];

  /** Station Widgets array. */
  inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** Station Widgets array to restore. */
  _inputFrameWidgetItems: GridsterItemWidget[] = [];

  /** The list of frames related to the associated station and container. */
  framesByType: GridsterItemWidget[] = [];

  /** Current user assigned to container. */
  currentUserAssigned: StationRosterMember[] | undefined;

  /** Current station information. */
  stationInformation!: StationInformation;

  /** Whether should disabled all question.*/
  selfAssignDisabledQuestions = false;

  /** Show only button delete widget in drawer. */
  deleteWidget = false;

  /** Show setting button widget. */
  showButtonSetting = false;

  /** Show detail popover in the widget. */
  showDetailWidgetPopover = false;

  /** It is being used from the map. */
  isUsedFromMap = false;

  /** To disable action while messages are appearing . */
  disableActions = false;

  /** True if the user is architect else False. */
  isArchitect = false;

  /** Feature flag for show Widget in Frames Update.*/
  showWidgetFramesUpdate = false;

  /** Feature flag for show widget header and subheader. */
  headerFeatureFlag = false;

  /** Feature flag order of operations. */
  orderOfOperations = false;

  /** Relationship widget flag. */
  relationshipWidgetFlag = false;

  /** Feature flag show container station overlay link. */
  containerStationOverlayFeature = false;

  /** Manual trigger data. */
  manualTriggerData?: ManualTrigger;

  /**
   * Prevent function.
   * @param event Event.
   */
  prevent = (event: BeforeUnloadEvent): void => {
    if (this.containerAnswer.length > 0) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  constructor(
    private containerService: ContainerService,
    private stationService: StationService,
    private sidenavDrawerService: SidenavDrawerService,
    private errorService: ErrorService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private popupService: PopupService,
    private userService: UserService,
    public mobileBrowserChecker: MobileBrowserChecker,
    @Inject(ChangeDetectorRef) private changeDetectorR: ChangeDetectorRef,
    private dialog: MatDialog,
    private routerNavigationService: RouterNavigationService,
    private powerService: PowerService,
    private splitService: SplitService,
    private inputFrameHelper: InputFrame,
    private titleService: Title,
  ) {
    this.containerForm = this.fb.group({
      containerTemplateForm: this.fb.control(''),
      inputFrameFieldForm: this.fb.control(''),
    });
    this.isUsedFromMap = this.router.url.includes('/map');
    this.windowResize();
  }

  /**
   * Check if can navigate.
   * @returns If can navigate.
   */
  canNavigate(): boolean {
    return this.containerAnswer.length === 0;
  }

  /** The current actions with their status provided by the signal. */
  currentActionsStatus: string[] = [];

  /** Helper action runner. */
  actionRunnerHelper = ActionRunnerHelper;

  /** Whether should be disabled all into container. */
  disableCurrentContainer = false;

  /** Feature flag show self assign. */
  showSelfAssign = false;

  /** Feature Flag for show widget column. */
  widgetDataPhase2Flag = false;

  /** Feature Flag for sorting widget. */
  multiSortingFeatureFlag = false;

  /**
   * Gets info about the container as well as forward and previous stations for a specific document.
   */
  ngOnInit(): void {
    this.getTreatment();
    this.sidenavDrawerService.setDrawer(this.detailDrawer);
    this.getParams();
    this.subscribeDrawerContext$();
    this.subscribeDocumentName$();
    this.subscribeContainerAnswer$();
    this.subscribeExecuteTriggerButton$();
    this.setConfigMobileGridster();

    this.updateSelfAssign$();
    this.reloadContainer$();
    /** Listen window beforeUnload. */
    this.preventReload();
    this.isArchitect = this.userService.isAdmin;
    this.saveContainerAnswer$();

    // To recover containers processing
    this.actionsContainersRunnerLogic([]);
  }

  /**
   * Subject update self assign.
   */
  updateSelfAssign$(): void {
    this.containerService.selfAssignEvent$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (
          data.containerId === this.containerId &&
          data.stationId === this.stationId
        ) {
          let selfAssign!: Question | undefined;

          for (
            let index = 0;
            index < this.inputFrameWidgetItems.length;
            index++
          ) {
            selfAssign = this.inputFrameWidgetItems[index].questions?.find(
              (Q) => Q.questionType === QuestionFieldType.SelfAssign,
            );
            if (selfAssign) break;
          }

          if (data.actionType === 'Assign') {
            selfAssign &&
              (selfAssign.answer = {
                value: this.userService.user.rithmId,
                questionRithmId: '',
                referAttribute: '',
                asJson: {
                  profileImageRithmId:
                    this.userService.user.profileImageRithmId,
                  firstName: this.userService.user.firstName,
                  lastName: this.userService.user.lastName,
                  email: this.userService.user.email,
                },
              });
          } else {
            selfAssign && (selfAssign.answer = undefined);
          }

          this.getAssignedUserToContainer();
        }
      });
  }

  /**
   * Discard changes on container.
   */
  reloadContainer$(): void {
    this.containerService.reloadContainer$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data) => {
        if (
          data.containerId === this.containerId &&
          data.stationId === this.stationId
        ) {
          this.containerAnswer = [];
          this.getContainerStationData();
        }
      });
  }

  /**
   * Subject update self assign.
   */
  saveContainerAnswer$(): void {
    this.containerService.saveContainerAnswer$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.saveContainerChanges();
      });
  }

  /**
   * Method to subscribe events to signal when detect who container is executed in actions.
   */
  subscribeEventSignalsActions$(): void {
    this.powerService.actionsStatusRunner$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((actionsStatus) => {
        this.actionsContainersRunnerLogic(actionsStatus);
      });
  }

  /**
   * Logic to detect containers working in actions.
   * @param actionsStatus ActionsStatus to containers.
   */
  private actionsContainersRunnerLogic(
    actionsStatus: ActionsStatusRunner[],
  ): void {
    this.currentActionsStatus =
      this.actionRunnerHelper.getContainersProcessing();
    this.disableCurrentContainer = this.currentActionsStatus.includes(
      this.containerId,
    );

    //Reload container when finish or failed actions to specific container.
    actionsStatus.map((action) => {
      if (
        action.containerRithmId === this.containerId &&
        this.actionRunnerHelper
          .affectedStationsRunnerAction(action)
          .includes(this.stationId)
      ) {
        if ([StateActions.Failed, StateActions.None].includes(action.status)) {
          if (
            this.actionRunnerHelper
              .actionTypesRunnerAction(action)
              .some((actionType) =>
                [ActionType.MoveContainer, ActionType.FlowContainer].includes(
                  actionType,
                ),
              ) &&
            this.manualTriggerData
          ) {
            const defaultMessage =
              action.status === StateActions.None
                ? 'Processes have been successfully completed.'
                : 'Something has gone wrong in the process, try again later.';
            this.manualTriggerData.containers[0].message =
              action.message || defaultMessage;
            this.flowedContainer();
          } else {
            this.manualTriggerData = undefined;
            this.getContainerStationData();
          }
        }
      }
    });
  }

  /**
   * Get flag widget in Frames Update.
   */
  private getTreatment(): void {
    const orgRithmId = this.userService.user.organization;
    this.splitService.initSdk(orgRithmId);
    this.splitService.sdkReady$.pipe(first()).subscribe({
      next: () => {
        this.showWidgetFramesUpdate =
          this.splitService.getWidgetFramesUpdate() === 'on';

        this.headerFeatureFlag = this.splitService.getWidgetHeader() === 'on';

        this.orderOfOperations =
          this.splitService.getOrderOfOperations() === 'on';

        this.showSelfAssign = this.splitService.getSelfAssign() === 'on';
        this.orderOfOperations && this.subscribeEventSignalsActions$();

        this.relationshipWidgetFlag =
          this.splitService.getRelationshipWidget() === 'on';

        this.containerStationOverlayFeature =
          this.splitService.containerStationOverlayLink() === 'on';
        this.widgetDataPhase2Flag =
          this.splitService.getWidgetDataTabPhase2() === 'on';

        this.multiSortingFeatureFlag =
          this.splitService.getMultiTierSorting() === 'on';
      },
    });
  }

  /**
   * Prevent close tab.
   */
  private preventReload(): void {
    window.addEventListener('beforeunload', this.prevent);
  }

  /**
   * Whether to show the backdrop for the comment and history drawers.
   * @returns Whether to show the backdrop.
   */
  get drawerHasBackdrop(): boolean {
    return this.sidenavDrawerService.drawerHasBackdrop;
  }

  /**
   * Is the current user an owner or an admin for this document.
   * @returns Validate if user is owner or admin of current document.
   */
  get isUserAdminOrOwner(): boolean {
    const ownerDocument = this.containerInformation.stationOwners?.find(
      (owner) => this.userService.user.rithmId === owner.rithmId,
    );
    return !!ownerDocument || this.userService.isAdmin;
  }

  /** Subscribe to drawerContext$. */
  private subscribeDrawerContext$(): void {
    this.sidenavDrawerService.drawerContext$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((context) => {
        this.drawerContext = context;
      });
  }

  /** Subscribe to triggerButtonSelected$. */
  private subscribeExecuteTriggerButton$(): void {
    this.containerService.triggerButtonSelected$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((trigger) => {
        if (
          trigger.buttonRithmId.length &&
          trigger.stationRithmId === this.stationId &&
          trigger.containerRithmId === this.containerId &&
          !trigger.fullModalView
        ) {
          this.saveContainerChanges(false, trigger.buttonRithmId);
        }
      });
  }

  /** Subscribe to containerName$. */
  private subscribeDocumentName$(): void {
    this.containerService.containerName$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((containerName) => {
        if (
          this.containerInformation &&
          containerName.baseName !== this.containerInformation.containerName
        ) {
          this.containerForm.markAllAsTouched();
        }
        this.containerName = containerName.baseName;
        this.titleService.setTitle(
          (this.containerName?.trim() || TermsGeneric.Container.Single)
            .split(' ')
            .map(_.upperFirst)
            .join(' '),
        );
        if (
          this.containerAnswer.length > 0 &&
          this.containerAnswer.some(
            (answer) => answer.containerRithmId !== this.containerId,
          )
        ) {
          this.containerAnswer = [];
        }
      });
  }

  /** Subscribe to containerAnswer$. */
  private subscribeContainerAnswer$(): void {
    this.containerService.containerAnswer$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((answer) => {
        /** The stationId and the containerId are compared to avoid conflicts with the widget containers. */
        if (
          this.containerId === answer.containerRithmId &&
          this.stationId === answer.stationRithmId
        ) {
          this.containerForm.markAllAsTouched();
          const answerIndexFound = this.containerAnswer.findIndex(
            ({ questionRithmId }) => questionRithmId === answer.questionRithmId,
          );
          if (answerIndexFound < 0) {
            /** Answer doesn't exists then add it. */
            answer.stationRithmId = this.containerInformation.stationRithmId;
            answer.containerRithmId =
              this.containerInformation.containerRithmId;
            this.containerAnswer.push(answer);
          } else {
            /** Answer exists then update its value. */
            this.containerAnswer[answerIndexFound].value = answer.value;
            if (answer.type === QuestionFieldType.File) {
              this.containerAnswer[answerIndexFound] = answer;
            }
          }
          this.containerService.fieldAnswersContainer$.next(
            this.containerAnswer,
          );
        }
      });
  }

  /**
   * Checks after the component views and child views.
   */
  ngAfterViewChecked(): void {
    // Set the maximum width using a css variable for the footer of the new interface.
    const scroll = document.querySelector('.drawer-content');
    document.documentElement.style.setProperty(
      '--max-width-footer',
      `${scroll?.clientWidth}px`,
    );
    if (!this.isWidget) {
      this.containerForm.controls['inputFrameFieldForm'].markAsTouched();
      this.containerForm.controls[
        'inputFrameFieldForm'
      ].updateValueAndValidity();
    }
    this.changeDetectorR.detectChanges();
  }

  /**
   * Attempts to retrieve the document info from the query params in the URL and make the requests.
   */
  private getParams(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (params) => {
        if (!params.stationId || !params.containerId) {
          this.handleInvalidParams();
        } else {
          this.containerId = params.containerId;
          this.stationId = params.stationId;

          this.getContainerStationData();
          this.getConnectedStations();
        }
      },
      error: (error: unknown) => {
        this.errorService.displayError(
          "Something went wrong on our end and we're looking into it. Please try again in a little while.",
          error,
        );
      },
    });
  }

  /**
   * Navigates the user back to dashboard and displays a message about the invalid params.
   */
  private handleInvalidParams(): void {
    this.navigateBack();
    this.errorService.displayError(
      'The link you followed is invalid. Please double check the URL and try again.',
      new Error(
        `Invalid params for ${TermsGeneric.Container.Single.toLowerCase()}`,
      ),
    );
  }

  /**
   * Navigates the user back to the dashboard page.
   */
  private navigateBack(): void {
    this.routerNavigationService.goBack();
  }

  /**
   * Validate frameWidgets of the container.
   * @param inputFrames Container inputFrames.
   */
  private logicFrameWidget(inputFrames: GridsterItemWidget[]): void {
    this.inputFrameWidgetItems = inputFrames;
    const gridCols = this.options.minCols ? this.options.minCols : 24;
    this.inputFrameWidgetItems.map((frames) => {
      // Validate to stablish row to frame and height to questions.
      if (frames.questions?.length) {
        this.inputFrameHelper.setHeightByDefaultToQuestions(frames);
        frames.minItemRows = this.inputFrameHelper.calculateRowFrame(
          frames.questions,
        );
      }
      /**
       * ?The next line will set each id in the order items should be displayed in responsive view.
       * ?This will start counting elements from left-to-right and then from top=to=bottom.
       */
      frames.id = frames.y === 0 ? frames.x : gridCols * frames.y + frames.x;
      frames.questions = frames.questions?.filter(
        (e) =>
          e.prompt !== 'Address Line 1' &&
          e.prompt !== 'Address Line 2' &&
          e.questionType !== QuestionFieldType.City &&
          e.questionType !== QuestionFieldType.State &&
          e.questionType !== QuestionFieldType.Zip,
      );
      frames.questions?.sort((a, b) => {
        if (a.questionType === QuestionFieldType.State) {
          a.possibleAnswers = STATES;
        }
        return (a.order || 0) > (b.order || 0) ? 1 : -1;
      });

      if (
        [
          WidgetType.Station,
          WidgetType.StationTableBanner,
          WidgetType.StationMultiline,
          WidgetType.StationMultilineBanner,
          WidgetType.Container,
          WidgetType.ContainerListBanner,
          WidgetType.ContainerProfileBanner,
        ].includes(frames.widgetType)
      ) {
        frames.imageId = JSON.parse(frames.data)?.imageId;
        frames.imageName = JSON.parse(frames.data)?.imageName;
        if (
          DashboardWidgets.stationWidgets.includes(frames.widgetType) &&
          !frames.settings
        ) {
          frames.settings = LibraryHelper.defaultWidgetSettings;
        }
      }
    });
    this.inputFrameWidgetItems.sort(function (a, b) {
      return a.id !== undefined && b.id !== undefined
        ? a.id > b.id
          ? 1
          : -1
        : 0;
    });
    this.changedOptions();
  }

  /**
   * Get data about the document and station the document is in.
   */
  private getContainerStationData(): void {
    this.containerLoading = true;
    this.getStationInfo();
    forkJoin([
      this.containerService.getContainerInfo(this.containerId, this.stationId),
      this.containerService.getContainerWidgets(
        this.containerId,
        this.stationId,
      ),
    ])
      .pipe(first())
      .subscribe({
        next: async ([container, inputFrames]) => {
          this.logicContainerInfo(container);
          this.logicFrameWidget(inputFrames);
          this.validateSelfAssignDisabled();
          this.containerLoading = false;
        },
        error: (error: unknown) => {
          this.navigateBack();
          this.containerLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Logic container info when receipt data.
   * @param containerInfo Container information station.
   */
  private async logicContainerInfo(
    containerInfo: ContainerStationInformation,
  ): Promise<void> {
    if (containerInfo) {
      this.containerInformation = containerInfo;
      /** Get the name for the flow button. */
      this.getFlowButtonName();
    }
    // Get the allow the previous button for the document.
    this.allowPreviousButton = await lastValueFrom(
      this.stationService.getAllowPreviousButton(this.stationId),
    );
  }

  /**
   * Retrieves a list of the connected stations for the given document.
   */
  private getConnectedStations(): void {
    this.containerService
      .getConnectedStationInfo(this.containerId, this.stationId)
      .pipe(first())
      .subscribe({
        next: (connectedStations) => {
          this.forwardStations = connectedStations.nextStations;
          this.previousStations = connectedStations.previousStations;
        },
        error: (error: unknown) => {
          this.navigateBack();
          this.errorService.displayError(
            `Failed to get connected ${TermsGeneric.Station.Plural.toLowerCase()} for this ${TermsGeneric.Container.Single.toLowerCase()}.`,
            error,
            false,
          );
        },
      });
  }

  /** This cancel button clicked show alert. */
  async cancelContainer(): Promise<void> {
    const response = await this.popupService.confirm({
      title: 'Are you sure?',
      message: `Your changes will be lost and you will return to the ${'map'}.`,
      okButtonText: 'Confirm',
      cancelButtonText: 'Close',
      important: true,
    });
    if (response) {
      this.navigateBack();
    }
  }

  /**
   * Check Click Outside Comment.
   * @param clickInside To catch event that verify click comment drawer outside.
   */
  checkClickOutsideComment(clickInside: boolean): void {
    if (
      !clickInside &&
      !this.clickSubHeader &&
      this.sidenavDrawerService.isDrawerOpen
    ) {
      this.sidenavDrawerService.closeDrawer();
      this.subHeaderComponent.activeItem = 'none';
    }
  }

  /**
   * Check click outside sub header.
   * @param clickInside To catch event that verify click comment drawer outside.
   */
  checkClickSubHeader(clickInside: boolean): void {
    this.clickSubHeader = clickInside;
  }

  /**
   * Open the station to move modal.
   * @param stations The stations affected by an action.
   */
  openStationToMoveModal(
    stations: ManualTriggerStation[] | ConnectedStationInfo[],
  ): void {
    if (stations.length > 1) {
      this.dialog.open(StationToMoveComponent, {
        data: {
          isChained: this.containerInformation.isChained,
          nextStations: stations,
          modalData: {
            containerRithmId: this.containerInformation.containerRithmId,
            stationRithmId: this.containerInformation.stationRithmId,
          },
        },
        disableClose: true,
      });

      return;
    }

    this.stationId =
      'stationRithmId' in stations[0]
        ? stations[0].stationRithmId
        : stations[0].rithmId;
    this.getContainerStationData();
    this.goToContainer(this.stationId);
  }

  /**
   * It is carried out after the container has flowed to the corresponding stations.
   */
  flowedContainer(): void {
    if (!this.manualTriggerData) return;

    const notificationFlow =
      this.manualTriggerData.containers[0].message ||
      `${
        this.termsGeneric.Station.Single
      } Rule does not match/allow the selected ${this.termsGeneric.Container.Plural.toLowerCase()}
    to ${this.termsGeneric.Flow.Single.toLowerCase()}`;
    /** This notification should only appear when the container not flowed. */
    if (this.manualTriggerData.stations.length) {
      if (this.containerInformation.isChained) {
        this.openStationToMoveModal(this.manualTriggerData.stations);
      } else {
        if (window.opener?.location?.pathname) {
          this.router.navigate([window.opener?.location?.pathname]);
        } else {
          this.disableActions = true;
          setTimeout(() => {
            this.navigateBack();
            this.disableActions = false;
          }, 1500);
        }
      }
    } else {
      this.getContainerStationData();
      this.getConnectedStations();
    }
    this.notify(notificationFlow);
    this.manualTriggerData = undefined;
    this.containerLoading = false;
  }

  /**
   * Execute Trigger Button.
   * @param buttonRithmId Button RitHmId selected.
   */
  executeTriggerButton(buttonRithmId: string): void {
    this.containerService
      .executeTriggerButtons(
        buttonRithmId,
        this.containerInformation.containerRithmId,
        this.containerInformation.stationRithmId,
      )
      .pipe(first())
      .subscribe({
        next: (triggerExecuted) => {
          this.manualTriggerData = triggerExecuted;
          const hasFlowed =
            triggerExecuted.containers.length &&
            triggerExecuted.containers[0].didFlow &&
            triggerExecuted.stations.length;

          if (hasFlowed) {
            if (
              !this.orderOfOperations ||
              (this.manualTriggerData?.containers[0].canFlow &&
                !this.manualTriggerData.containers[0].toStationRithmIds.length)
            ) {
              this.flowedContainer();
            }
          } else {
            this.getContainerStationData();
          }

          const successMessage =
            this.manualTriggerData?.containers[0].message ||
            this.orderOfOperations
              ? 'Processes have been started.'
              : 'Processes have been successfully completed.';
          const errorMessage =
            this.manualTriggerData?.containers[0].message ||
            `${this.termsGeneric.Station.Single} Rule does not match/allow ` +
              `the selected ${this.termsGeneric.Container.Single.toLowerCase()} to ${this.termsGeneric.Flow.Single.toLowerCase()}`;
          const message = this.manualTriggerData?.stations.length
            ? successMessage
            : errorMessage;

          this.notify(
            message,
            !this.orderOfOperations && !this.manualTriggerData?.stations.length,
          );

          if (
            !this.orderOfOperations ||
            (this.manualTriggerData?.containers[0].canFlow &&
              !this.manualTriggerData.containers[0].toStationRithmIds.length)
          ) {
            this.containerLoading = false;
          }
        },
        error: () => {
          this.containerLoading = false;
          this.notify(
            'Something has gone wrong in the process, try again later.',
          );
        },
      });
  }

  /**
   * Save container changes with the save button.
   * @param redirectToDocument Redirect to container page.
   * @param buttonRithmId In case of selecting a button trigger.
   */
  saveContainerChanges(
    redirectToDocument = false,
    buttonRithmId?: string,
  ): void {
    this.containerLoading = true;
    if (
      this.containerAnswer.some(
        (urlField) => urlField.type === QuestionFieldType.URL,
      )
    ) {
      const urlFields = this.containerAnswer.filter(
        (field) => field.type === QuestionFieldType.URL,
      );
      urlFields.map((answer) => {
        answer.value = this.verifyHttpsFormat(answer.value);
      });
    }
    this.containerService
      .saveContainerAnswer(
        this.containerInformation.containerRithmId,
        this.containerAnswer,
      )
      .pipe(first())
      .subscribe({
        next: () => {
          this.containerForm.markAsUntouched();
          this._inputFrameWidgetItems = [...this.inputFrameWidgetItems];
          this.inputFrameWidgetItems = [];
          /** Checks if a trigger button has been selected. */
          if (buttonRithmId?.length) {
            this.executeTriggerButton(buttonRithmId);
          } else {
            if (this.shouldFlowContainer) {
              this.manualTriggerFlow();
              this.shouldFlowContainer = false;
            } else if (this.shouldFlowPreviousContainer) {
              this.shouldFlowPreviousContainer = false;
              this.flowContainerToPreviousStation();
            } else {
              this.getContainerStationData();
            }
          }

          if (redirectToDocument) {
            this.goToContainer();
          }
          const containerName = this.containerAnswer.find(
            (answer) => answer.type === QuestionFieldType.ContainerName,
          );
          containerName && (this.containerName = containerName.value);

          this.containerAnswer = [];
          this.notify(`${this.containerName} saved successfully`);
          !this.forwardStations.length && (this.containerLoading = false);
        },
        error: (error: unknown) => {
          this.containerLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Save the document name.
   */
  private saveDocumentName(): void {
    this.containerService
      .updateContainerName(
        this.containerInformation.containerRithmId,
        this.containerName,
      )
      .pipe(first())
      .subscribe({
        error: (error: unknown) => {
          this.containerLoading = false;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Navigate the user to the document page.
   * @param stationRithmId Station rithm id to use navigation.
   */
  goToContainer(stationRithmId = ''): void {
    this.router.navigate(
      [
        '/',
        `${TermsGeneric.Container.Single.toLowerCase()}`,
        this.containerInformation.containerRithmId,
      ],
      {
        queryParams: {
          containerId: this.containerInformation.containerRithmId,
          stationId: stationRithmId || this.containerInformation.stationRithmId,
        },
      },
    );
  }

  /**
   * Move document flow from current station to previous station.
   */
  async confirmFlowContainerToPreviousStation(): Promise<void> {
    const messageIsChained = this.containerInformation.isChained
      ? `previous ${TermsGeneric.Station.Single.toLowerCase()}`
      : `${TermsGeneric.Board.Lower.Single}`;
    const confirm = await this.popupService.confirm({
      title: 'Are you sure?',
      message: `\nYou will be redirected to the ${messageIsChained}.`,
      okButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    });

    if (confirm) {
      if (this.containerAnswer.length) {
        this.shouldFlowPreviousContainer = true;
        this.saveContainerChanges();
      } else {
        this.flowContainerToPreviousStation();
      }
    }
  }

  /**
   * Flow container to previous station.
   * @param stationsToFlow Receives list of rithmIds of stations to flow.
   */
  private flowContainerToPreviousStation(stationsToFlow: string[] = []): void {
    this.containerLoading = true;
    const previousStations: string[] = stationsToFlow.length
      ? stationsToFlow
      : this.previousStations.map((item) => item.rithmId);
    const flowDoc: MoveContainer = {
      fromStationRithmId: this.stationId,
      toStationRithmIds:
        this.containerInformation.isChained && !stationsToFlow.length
          ? []
          : previousStations,
      containerRithmIds: [this.containerId],
    };

    this.containerService
      .flowContainerToPreviousStation(flowDoc)
      .pipe(first())
      .subscribe({
        next: () => {
          this.containerLoading = false;
          this.notify(
            `Successfully ${this.termsGeneric.Container.Single.toLowerCase()}
            ${this.termsGeneric.Flow.Past.toLowerCase()}
            to the previous ${this.termsGeneric.Station.Single.toLowerCase()}`,
          );
          if (this.containerInformation.isChained) {
            this.openStationToMoveModal(this.previousStations);
          } else {
            this.router.navigateByUrl(this.termsGeneric.Board.Lower.Plural);
          }
        },
        error: (error: unknown) => {
          const { status } = error as HttpErrorResponse;
          this.containerLoading = false;
          this.notify(
            `The ${TermsGeneric.Container.Single.toLowerCase()} failed to flow previously, Please try again in a little while.`,
            true,
          );
          if (status === 400) {
            this.openModalFlowContainerToPreviousStation();
          }
        },
      });
  }

  /**
   * Test modal Flow container to previous station.
   */
  openModalFlowContainerToPreviousStation(): void {
    const dialog = this.dialog.open(FlowContainerPreviousStationComponent, {
      data: {
        backStations: this.previousStations,
        modalData: {
          containerRithmId: this.containerInformation.containerRithmId,
          stationRithmId: this.containerInformation.stationRithmId,
        },
      },
      disableClose: true,
    });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((stationsRithmIdtoFlow: string[]) => {
        if (stationsRithmIdtoFlow?.length) {
          this.flowContainerToPreviousStation(stationsRithmIdtoFlow);
        }
      });
  }

  /**
   * Get flow button name.
   */
  getFlowButtonName(): void {
    this.stationService
      .getFlowButtonText(this.stationId)
      .pipe(first())
      .subscribe({
        next: (flowButtonText) => {
          this.flowButtonName = flowButtonText || this.termsGeneric.Flow.Single;
        },
        error: (error: unknown) => {
          this.flowButtonName = this.termsGeneric.Flow.Single;
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Get list of frames by type.
   */
  getDataLinkFrames(): void {
    this.containerService
      .getDataLinkFrames(
        this.containerInformation.stationRithmId,
        this.containerInformation.containerRithmId,
        WidgetType.DataLinkWidget,
      )
      .pipe(first())
      .subscribe({
        next: (frames) => {
          this.framesByType = frames;
        },
        error: (error: unknown) => {
          this.errorService.displayError(
            "Something went wrong on our end and we're looking into it. Please try again in a little while.",
            error,
          );
        },
      });
  }

  /**
   * Flow containers with manual trigger.
   *
   */
  manualTriggerFlow(): void {
    this.powerService
      .manualTriggerFlow(this.containerInformation.stationRithmId, [
        this.containerInformation.containerRithmId,
      ])
      .pipe(first())
      .subscribe({
        next: (manualTriggerData) => {
          this.manualTriggerData = manualTriggerData;
          if (
            !this.orderOfOperations ||
            (this.manualTriggerData?.containers[0].canFlow &&
              !this.manualTriggerData.containers[0].toStationRithmIds.length)
          ) {
            this.flowedContainer();
            this.containerLoading = false;
          }
        },
        error: () => {
          this.containerLoading = false;
          this.notify(
            `${TermsGeneric.Container.Single.toUpperCase()} could not flow properly, Please try again in a little while.`,
            true,
          );
        },
      });
  }

  /**
   * Change options in grid.
   *
   */
  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  /**
   * Needed to resize a mobile browser when the scrollbar hides.
   */
  windowResize(): void {
    fromEvent(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.setConfigMobileGridster();
      });
  }

  /** Set config break point in mobile. */
  private setConfigMobileGridster(): void {
    this.options.mobileBreakpoint = this.mobileBrowserChecker.isMobileDevice()
      ? 1920
      : 640;
    this.changedOptions();
  }

  /**
   * Notify and show pop-up for actions the admin.
   * @param message Message for show in popup.
   * @param isError If the popup is an error message.
   * @param icon Icon as HTML example: <i class="fa-light fa-circle-exclamation"></i>.
   */
  private notify(message: string, isError = false, icon = ''): void {
    this.popupService.notify(message, isError, undefined, icon);
  }

  /**
   * Get station info.
   */
  getStationInfo(): void {
    this.stationService
      .getStationInfo(this.stationId)
      .pipe(first())
      .subscribe({
        next: (stationInfo) => {
          this.stationInformation = stationInfo;
          this.stationInformation.assignmentRequired
            ? this.getAssignedUserToContainer()
            : this.validateSelfAssignDisabled();
        },
      });
  }

  /**
   * Get assigned user information.
   */
  getAssignedUserToContainer(): void {
    this.containerService
      .getAssignedUserToContainer(this.containerId, this.stationId, true)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.currentUserAssigned = data;
          this.validateSelfAssignDisabled();
        },
      });
  }

  /**
   * Validate whether disabled all question.
   */
  validateSelfAssignDisabled(): void {
    if (this.showSelfAssign) {
      let selfAssign!: Question | undefined;

      for (let index = 0; index < this.inputFrameWidgetItems.length; index++) {
        selfAssign = this.inputFrameWidgetItems[index].questions?.find(
          (Q) => Q.questionType === QuestionFieldType.SelfAssign,
        );
        if (selfAssign) break;
      }

      this.selfAssignDisabledQuestions =
        ((selfAssign?.isRequired ||
          this.stationInformation?.assignmentRequired) &&
          selfAssign?.answer?.value !== this.userService.user.rithmId &&
          selfAssign?.answer?.value !== null &&
          selfAssign?.answer !== undefined) ||
        ((!this.currentUserAssigned?.length ||
          this.currentUserAssigned[0]?.rithmId !==
            this.userService.user.rithmId) &&
          this.stationInformation?.assignmentRequired);
    }
  }

  /**
   * Https format added in value and validate.
   * @param urlValue Value of url to be validated.
   * @returns Validated data.
   */
  private verifyHttpsFormat(urlValue: string): string {
    const urlRegex = new RegExp(/^(https:\/\/)|^(http:\/\/)/g);
    if (!urlRegex.test(urlValue) && urlValue.length > 0) {
      const val = 'https://' + urlValue;
      return val;
    }
    return urlValue;
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    window.removeEventListener('beforeunload', this.prevent);
  }
}
