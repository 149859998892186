<section class="container-template-section">
  @if (!displayGridTemplate) {
    <ng-container [formGroup]="documentTemplateForm">
      <h6 class="py-3" [ngClass]="{ 'text-sm': isWidget }">
        General Instructions
      </h6>
      <p class="whitespace-pre-line" [ngClass]="{ 'text-sm': isWidget }">
        {{ stationInstructions }}
      </p>
      <hr class="my-3" />

      <section class="data-link-section"></section>

      @for (field of documentFields; track field.rithmId) {
        <app-inline-field-update
          [field]="field"
          formControlName="documentFieldForm"
          [containerRithmId]="containerRithmId"
          [stationRithmId]="stationRithmId"
        />
      }
    </ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="gridsterSection" />
  }
</section>

<ng-template #gridsterSection>
  <section
    class="gridster-container pb-4 w-full"
    [formGroup]="documentTemplateForm"
  >
    <!-- The cdkScrollable needed for the question userSelect per mat-autocomplete element panel to follow its reference element. -->
    <gridster
      class="bg-white overflow-auto scrollbar h-[calc(100vh-290px)] disable-scroll-when-opened-autocomplete"
      [options]="options"
      cdkScrollable
    >
      @defer (on immediate) {
        @for (
          widgetItem of inputFrameWidgetItems;
          track widgetItem.rithmId;
          let i = $index
        ) {
          <gridster-item
            [id]="'gridster-item-' + i"
            [item]="widgetItem"
            [style]="
              widgetItem.rithmId === widgetIdPopperIsOpened
                ? 'z-index: 3 !important'
                : ''
            "
            class="gridster-item"
            [ngClass]="{
              'shadow-md rounded-md': isDashboardWidget(widgetItem.widgetType)
            }"
          >
            <div class="h-gridster">
              <!-- Show headline widget -->
              @if (widgetItem.widgetType === frameType.HeadlineWidget) {
                <app-headline-widget [widget]="widgetItem" />
              }

              <!-- Show headline widget -->
              @if (widgetItem.widgetType === frameType.TitleWidget) {
                <app-title-widget [widget]="widgetItem" />
              }

              <!-- Show body text widget -->
              @if (widgetItem.widgetType === frameType.BodyWidget) {
                <app-body-text-widget [widget]="widgetItem" />
              }

              <!-- Show body text widget -->
              @if (widgetItem.widgetType === frameType.CircleImageWidget) {
                <app-circle-image-widget />
              }

              <!-- Station Widgets -->
              @if (isStationWidget(widgetItem.widgetType)) {
                <app-station-widget
                  [relationshipWidgetFlag]="relationshipWidgetFlag"
                  [parentContainerRithmId]="containerRithmId"
                  [parentStationRithmId]="stationRithmId"
                  [dataWidget]="widgetItem.data"
                  [widgetType]="widgetItem.widgetType"
                  [widgetId]="widgetItem.rithmId"
                  [widgetSettings]="widgetItem.settings"
                  [image]="{
                    imageId: widgetItem.imageId || null,
                    imageName: widgetItem.imageName || null
                  }"
                  [editMode]="false"
                  [isArchitect]="isArchitect"
                  [dashboardPermission]="true"
                  [limitListPerPage]="limitListPerPage(widgetItem)"
                  [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                  [headerFeatureFlag]="headerFeatureFlag"
                  [orderOfOperations]="orderOfOperations"
                  [showSelfAssign]="showSelfAssign"
                  [containerStationOverlayFeature]="
                    containerStationOverlayFeature
                  "
                  [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
                  [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- Container Widgets -->
              @if (isContainerWidget(widgetItem.widgetType)) {
                <app-container-widget
                  [dataWidget]="widgetItem.data"
                  [widgetItem]="widgetItem"
                  [editMode]="false"
                  [isArchitect]="isArchitect"
                  [dashboardPermission]="true"
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- Groups Widget -->
              @if (widgetItem.widgetType === frameType.StationGroupSearch) {
                <app-group-search-widget
                  [dataWidget]="widgetItem.data"
                  [widgetType]="widgetItem.widgetType"
                  [isArchitect]="isArchitect"
                  [editMode]="false"
                  [dashboardPermission]="true"
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- Groups traffic Widget -->
              @if (widgetItem.widgetType === frameType.StationGroupTraffic) {
                <app-group-traffic-widget
                  [dataWidget]="widgetItem.data"
                  [editMode]="false"
                  [isArchitect]="isArchitect"
                  [widgetItem]="widgetItem"
                  [isMobileDevice]="mobileBrowserChecker.isMobileDevice()"
                  [indexWidget]="i"
                  [dashboardPermission]="true"
                  [headerFeatureFlag]="headerFeatureFlag"
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- StationPreBuilt -->
              @if (widgetItem.widgetType === frameType.PreBuiltStation) {
                <app-station-pre-built-widget
                  [editMode]="false"
                  [dashboardPermission]="true"
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- ContainerPreBuilt -->
              @if (widgetItem.widgetType === frameType.PreBuiltContainer) {
                <app-container-pre-built-widget
                  [editMode]="false"
                  [widgetId]="widgetItem.rithmId"
                  [widgetType]="widgetItem.widgetType"
                  [dashboardPermission]="true"
                  [isArchitect]="isArchitect"
                  [headerFeatureFlag]="headerFeatureFlag"
                  [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                  [orderOfOperations]="orderOfOperations"
                  [relationshipWidgetFlag]="relationshipWidgetFlag"
                  [showSelfAssign]="showSelfAssign"
                  [containerStationOverlayFeature]="
                    containerStationOverlayFeature
                  "
                  [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
                  [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- Group container widget -->
              @if (widgetItem.widgetType === frameType.GroupContainerTable) {
                <app-group-container-widget
                  [relationshipWidgetFlag]="relationshipWidgetFlag"
                  [dataWidget]="widgetItem.data"
                  [editMode]="false"
                  [widgetId]="widgetItem.rithmId"
                  [widgetType]="widgetItem.widgetType"
                  [dashboardPermission]="true"
                  [limitListPerPage]="limitListPerPage(widgetItem)"
                  [headerFeatureFlag]="headerFeatureFlag"
                  [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                  [isArchitect]="isArchitect"
                  [orderOfOperations]="orderOfOperations"
                  [showSelfAssign]="showSelfAssign"
                  [containerStationOverlayFeature]="
                    containerStationOverlayFeature
                  "
                  [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
                  [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- Input frame widget -->
              @if (widgetItem.widgetType === frameType.InputWidget) {
                <app-input-frame-widget
                  [disableAllQuestions]="disableCurrentContainer"
                  [canNotEditContainer]="canNotEditContainer"
                  [fields]="widgetItem.questions"
                  stationViewMode="preview"
                  [stationRithmId]="containerInformation.stationRithmId"
                  [containerRithmId]="containerInformation.containerRithmId"
                  [parentStationRithmId]="
                    containerInformation.parentStationRithmId
                  "
                  [parentContainerRithmId]="
                    containerInformation.parentContainerRithmId
                  "
                  [id]="widgetItem.rithmId"
                  [widgetData]="widgetItem.data"
                  [isStation]="false"
                  [parentContainer]="containerInformation.parentContainer"
                  [parentStation]="containerInformation.parentStation"
                  [isArchitect]="isArchitect"
                  [containerAnswer]="containerAnswer"
                  formControlName="inputFrameFieldForm"
                />
              }

              <!-- Relationship widget -->
              @if (widgetItem.widgetType === frameType.RelationshipWidget) {
                <app-relationship-widget
                  [relationshipWidgetFlag]="relationshipWidgetFlag"
                  [widgetId]="widgetItem.widgetFrameRithmId || ''"
                  [widgetItem]="widgetItem"
                  [dataWidget]="widgetItem.data"
                  [widgetType]="widgetItem.widgetType"
                  [image]="{
                    imageId: widgetItem.imageId || null,
                    imageName: widgetItem.imageName || null
                  }"
                  [editMode]="false"
                  [isArchitect]="isArchitect"
                  [dashboardPermission]="true"
                  [limitListPerPage]="limitListPerPage(widgetItem)"
                  [showWidgetFramesUpdate]="showWidgetFramesUpdate"
                  [headerFeatureFlag]="headerFeatureFlag"
                  [orderOfOperations]="orderOfOperations"
                  [parentContainerRithmId]="containerRithmId"
                  [currentContainerRithmId]="containerRithmId"
                  [modePreview]="false"
                  [showSelfAssign]="showSelfAssign"
                  [widgetColumnFeatureFlag]="widgetColumnFeatureFlag"
                  [multiSortingFeatureFlag]="multiSortingFeatureFlag"
                  [containerStationOverlayFeature]="
                    containerStationOverlayFeature
                  "
                  (toggleDrawer)="toggleWidgetDrawer(widgetItem, i, $event)"
                  (deleteWidget)="removeWidget()"
                />
              }

              <!-- toolbar image widget -->
              @if (widgetItem.widgetType === frameType.ImageWidget) {
                <app-toolbar-image-widget [dataWidget]="widgetItem.data" />
              }
            </div>
          </gridster-item>
        }
      }
    </gridster>
  </section>
</ng-template>
